import validate from "/home/runner/work/sso/sso/client/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "01-required-user-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/01-required-user.authenticated.ts"),
  "analytics-identify-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/analytics-identify.authenticated.ts"),
  "datadog-identify-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/datadog-identify.authenticated.ts"),
  "fullstory-identify-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/fullstory-identify.authenticated.ts"),
  "honeybadger-context-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/honeybadger-context.authenticated.ts"),
  "hubspot-update-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/hubspot-update.authenticated.ts"),
  "intercom-update-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/intercom-update.authenticated.ts"),
  "restrict-to-superuser": () => import("/home/runner/work/sso/sso/client/middleware/restrictToSuperuser.ts"),
  "zendesk-settings-authenticated": () => import("/home/runner/work/sso/sso/client/middleware/zendesk-settings.authenticated.ts"),
  "zendesk-settings-public": () => import("/home/runner/work/sso/sso/client/middleware/zendesk-settings.public.ts")
}