import { default as portal8riTasGGBPMeta } from "/home/runner/work/sso/sso/client/pages/(public)/login/portal.vue?macro=true";
import { default as decline0Wmrh17Bn9Meta } from "/home/runner/work/sso/sso/client/pages/(public)/network/invitations/[code]/decline.vue?macro=true";
import { default as _91invitation_id_93vVbiKz7xiMMeta } from "/home/runner/work/sso/sso/client/pages/(public)/register/invitations/accept/[invitation_id].vue?macro=true";
import { default as _91_46_46_46slug_93btSLI3MmLpMeta } from "/home/runner/work/sso/sso/client/pages/[...slug].vue?macro=true";
import { default as joinEMneZYZxOcMeta } from "/home/runner/work/sso/sso/client/pages/account/join.vue?macro=true";
import { default as missingn50Dp7zEbNMeta } from "/home/runner/work/sso/sso/client/pages/account/missing.vue?macro=true";
import { default as connectionsuBg2MwEbTrMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/connections.vue?macro=true";
import { default as contractedBiHJl3E59iMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/convert/contracted.vue?macro=true";
import { default as self_serviceKdXtU1iOfBMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/convert/self_service.vue?macro=true";
import { default as indexqByHNvSUvtMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/index.vue?macro=true";
import { default as joinVGa67l5zPPMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/join.vue?macro=true";
import { default as _91productId_93GT5Vx3l60BMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/subscriptions/[productId].vue?macro=true";
import { default as usagesqfDQTnbFlEMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/usages.vue?macro=true";
import { default as usersAl3MdMgUsQMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/users.vue?macro=true";
import { default as newSxZKsHanVSMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts/new.vue?macro=true";
import { default as accountszd5M0FlKylMeta } from "/home/runner/work/sso/sso/client/pages/admin/accounts.vue?macro=true";
import { default as connectionsJ8i63Q8r4cMeta } from "/home/runner/work/sso/sso/client/pages/admin/connections.vue?macro=true";
import { default as data_migrationsLubp8sG5wuMeta } from "/home/runner/work/sso/sso/client/pages/admin/data_migrations.vue?macro=true";
import { default as accountsRvTTPx8Z8EMeta } from "/home/runner/work/sso/sso/client/pages/admin/users/[id]/accounts.vue?macro=true";
import { default as users42BtJdDyESMeta } from "/home/runner/work/sso/sso/client/pages/admin/users.vue?macro=true";
import { default as adminyJsNZsuMJTMeta } from "/home/runner/work/sso/sso/client/pages/admin.vue?macro=true";
import { default as activityiCN9QwbKuKMeta } from "/home/runner/work/sso/sso/client/pages/billing/activity.vue?macro=true";
import { default as indexHqd7aSYW5jMeta } from "/home/runner/work/sso/sso/client/pages/billing/index.vue?macro=true";
import { default as delete2XwPtVWvwDMeta } from "/home/runner/work/sso/sso/client/pages/billing/wallet/payment/delete.vue?macro=true";
import { default as editEcv7F3PvVUMeta } from "/home/runner/work/sso/sso/client/pages/billing/wallet/payment/edit.vue?macro=true";
import { default as index9cH4NhLNk5Meta } from "/home/runner/work/sso/sso/client/pages/billing/wallet/payment/index.vue?macro=true";
import { default as replacepQWEpi0K5sMeta } from "/home/runner/work/sso/sso/client/pages/billing/wallet/payment/replace.vue?macro=true";
import { default as rechargejmUwfYhK2gMeta } from "/home/runner/work/sso/sso/client/pages/billing/wallet/recharge.vue?macro=true";
import { default as walletWtNXkDIYcVMeta } from "/home/runner/work/sso/sso/client/pages/billing/wallet.vue?macro=true";
import { default as billingb5vkK9HUBNMeta } from "/home/runner/work/sso/sso/client/pages/billing.vue?macro=true";
import { default as select_accountUIV5dwdMZFMeta } from "/home/runner/work/sso/sso/client/pages/connect/[code]/select_account.vue?macro=true";
import { default as indexlqbKfgpNV9Meta } from "/home/runner/work/sso/sso/client/pages/connections/index.vue?macro=true";
import { default as invitationsjlwYk0Gk2SMeta } from "/home/runner/work/sso/sso/client/pages/connections/invitations.vue?macro=true";
import { default as connectionsp2L30vHwARMeta } from "/home/runner/work/sso/sso/client/pages/connections.vue?macro=true";
import { default as feedbackY1Jw7YFmIgMeta } from "/home/runner/work/sso/sso/client/pages/domains/[id]/feedback.vue?macro=true";
import { default as verifyoylBcmy9QhMeta } from "/home/runner/work/sso/sso/client/pages/domains/[id]/verify.vue?macro=true";
import { default as domainsXUaCqEg9q6Meta } from "/home/runner/work/sso/sso/client/pages/domains.vue?macro=true";
import { default as pricingUPZ4WiPoVyMeta } from "/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/[featureId]/pricing.vue?macro=true";
import { default as disable4mVzWnfb1dMeta } from "/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/disable.vue?macro=true";
import { default as enablerd20ijDAHsMeta } from "/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/enable.vue?macro=true";
import { default as forbiddens1jod3goiPMeta } from "/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/forbidden.vue?macro=true";
import { default as featuresEZQ12QZSL4Meta } from "/home/runner/work/sso/sso/client/pages/features.vue?macro=true";
import { default as indexKZIZ5jlI0zMeta } from "/home/runner/work/sso/sso/client/pages/index.vue?macro=true";
import { default as profile8UAJvSAdNYMeta } from "/home/runner/work/sso/sso/client/pages/profile.vue?macro=true";
import { default as _91id_93tc1qfywrEYMeta } from "/home/runner/work/sso/sso/client/pages/redirect/accounts/[id].vue?macro=true";
import { default as join_accountyBi6OH4PxrMeta } from "/home/runner/work/sso/sso/client/pages/redirect/join_account.vue?macro=true";
import { default as status5Rj8vZoTubMeta } from "/home/runner/work/sso/sso/client/pages/redirect/status.vue?macro=true";
import { default as indexTXB5gBxVjSMeta } from "/home/runner/work/sso/sso/client/pages/register/[registrationPath]/index.vue?macro=true";
import { default as select_accountjKGqxYdVRxMeta } from "/home/runner/work/sso/sso/client/pages/select_account.vue?macro=true";
import { default as settingsxR4N0YiP3YMeta } from "/home/runner/work/sso/sso/client/pages/settings.vue?macro=true";
import { default as unconfirmedxGyj4wleh8Meta } from "/home/runner/work/sso/sso/client/pages/unconfirmed.vue?macro=true";
import { default as indexvb9dbeaXa8Meta } from "/home/runner/work/sso/sso/client/pages/users/[id]/index.vue?macro=true";
import { default as indexUa9lZFzYStMeta } from "/home/runner/work/sso/sso/client/pages/users/current/index.vue?macro=true";
import { default as indexaiCgbDmraOMeta } from "/home/runner/work/sso/sso/client/pages/users/index.vue?macro=true";
import { default as newXHQJ3B1ZMBMeta } from "/home/runner/work/sso/sso/client/pages/users/new.vue?macro=true";
import { default as indexyX3aT5ghpzMeta } from "/home/runner/work/sso/sso/client/pages/volume/[itemName]/index.vue?macro=true";
export default [
  {
    name: portal8riTasGGBPMeta?.name ?? "(public)-login-portal",
    path: portal8riTasGGBPMeta?.path ?? "/login/portal",
    meta: { ...(portal8riTasGGBPMeta || {}), ...{"layout":"public","middleware":["zendesk-settings-public"]} },
    alias: portal8riTasGGBPMeta?.alias || [],
    redirect: portal8riTasGGBPMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/(public)/login/portal.vue").then(m => m.default || m)
  },
  {
    name: decline0Wmrh17Bn9Meta?.name ?? "(public)-network-invitations-code-decline",
    path: decline0Wmrh17Bn9Meta?.path ?? "/network/invitations/:code()/decline",
    meta: { ...(decline0Wmrh17Bn9Meta || {}), ...{"layout":"public","middleware":["zendesk-settings-public"]} },
    alias: decline0Wmrh17Bn9Meta?.alias || [],
    redirect: decline0Wmrh17Bn9Meta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/(public)/network/invitations/[code]/decline.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93vVbiKz7xiMMeta?.name ?? "(public)-register-invitations-accept-invitation_id",
    path: _91invitation_id_93vVbiKz7xiMMeta?.path ?? "/register/invitations/accept/:invitation_id()",
    meta: { ...(_91invitation_id_93vVbiKz7xiMMeta || {}), ...{"layout":"public","middleware":["zendesk-settings-public"]} },
    alias: _91invitation_id_93vVbiKz7xiMMeta?.alias || [],
    redirect: _91invitation_id_93vVbiKz7xiMMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/(public)/register/invitations/accept/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93btSLI3MmLpMeta?.name ?? "slug",
    path: _91_46_46_46slug_93btSLI3MmLpMeta?.path ?? "/:slug(.*)*",
    meta: { ...(_91_46_46_46slug_93btSLI3MmLpMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: _91_46_46_46slug_93btSLI3MmLpMeta?.alias || [],
    redirect: _91_46_46_46slug_93btSLI3MmLpMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: joinEMneZYZxOcMeta?.name ?? "account-join",
    path: joinEMneZYZxOcMeta?.path ?? "/account/join",
    meta: { ...(joinEMneZYZxOcMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: joinEMneZYZxOcMeta?.alias || [],
    redirect: joinEMneZYZxOcMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/account/join.vue").then(m => m.default || m)
  },
  {
    name: missingn50Dp7zEbNMeta?.name ?? "account-missing",
    path: missingn50Dp7zEbNMeta?.path ?? "/account/missing",
    meta: { ...(missingn50Dp7zEbNMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: missingn50Dp7zEbNMeta?.alias || [],
    redirect: missingn50Dp7zEbNMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/account/missing.vue").then(m => m.default || m)
  },
  {
    name: adminyJsNZsuMJTMeta?.name ?? "admin",
    path: adminyJsNZsuMJTMeta?.path ?? "/admin",
    meta: { ...(adminyJsNZsuMJTMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: adminyJsNZsuMJTMeta?.alias || [],
    redirect: adminyJsNZsuMJTMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: accountszd5M0FlKylMeta?.name ?? "admin-accounts",
    path: accountszd5M0FlKylMeta?.path ?? "accounts",
    meta: { ...(accountszd5M0FlKylMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: accountszd5M0FlKylMeta?.alias || [],
    redirect: accountszd5M0FlKylMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts.vue").then(m => m.default || m),
    children: [
  {
    name: connectionsuBg2MwEbTrMeta?.name ?? "admin-accounts-id-connections",
    path: connectionsuBg2MwEbTrMeta?.path ?? ":id()/connections",
    meta: { ...(connectionsuBg2MwEbTrMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: connectionsuBg2MwEbTrMeta?.alias || [],
    redirect: connectionsuBg2MwEbTrMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/connections.vue").then(m => m.default || m)
  },
  {
    name: contractedBiHJl3E59iMeta?.name ?? "admin-accounts-id-convert-contracted",
    path: contractedBiHJl3E59iMeta?.path ?? ":id()/convert/contracted",
    meta: { ...(contractedBiHJl3E59iMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: contractedBiHJl3E59iMeta?.alias || [],
    redirect: contractedBiHJl3E59iMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/convert/contracted.vue").then(m => m.default || m)
  },
  {
    name: self_serviceKdXtU1iOfBMeta?.name ?? "admin-accounts-id-convert-self_service",
    path: self_serviceKdXtU1iOfBMeta?.path ?? ":id()/convert/self_service",
    meta: { ...(self_serviceKdXtU1iOfBMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: self_serviceKdXtU1iOfBMeta?.alias || [],
    redirect: self_serviceKdXtU1iOfBMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/convert/self_service.vue").then(m => m.default || m)
  },
  {
    name: indexqByHNvSUvtMeta?.name ?? "admin-accounts-id",
    path: indexqByHNvSUvtMeta?.path ?? ":id()",
    meta: { ...(indexqByHNvSUvtMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexqByHNvSUvtMeta?.alias || [],
    redirect: indexqByHNvSUvtMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: joinVGa67l5zPPMeta?.name ?? "admin-accounts-id-join",
    path: joinVGa67l5zPPMeta?.path ?? ":id()/join",
    meta: { ...(joinVGa67l5zPPMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: joinVGa67l5zPPMeta?.alias || [],
    redirect: joinVGa67l5zPPMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/join.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93GT5Vx3l60BMeta?.name ?? "admin-accounts-id-subscriptions-productId",
    path: _91productId_93GT5Vx3l60BMeta?.path ?? ":id()/subscriptions/:productId()",
    meta: { ...(_91productId_93GT5Vx3l60BMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: _91productId_93GT5Vx3l60BMeta?.alias || [],
    redirect: _91productId_93GT5Vx3l60BMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/subscriptions/[productId].vue").then(m => m.default || m)
  },
  {
    name: usagesqfDQTnbFlEMeta?.name ?? "admin-accounts-id-usages",
    path: usagesqfDQTnbFlEMeta?.path ?? ":id()/usages",
    meta: { ...(usagesqfDQTnbFlEMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: usagesqfDQTnbFlEMeta?.alias || [],
    redirect: usagesqfDQTnbFlEMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/usages.vue").then(m => m.default || m)
  },
  {
    name: usersAl3MdMgUsQMeta?.name ?? "admin-accounts-id-users",
    path: usersAl3MdMgUsQMeta?.path ?? ":id()/users",
    meta: { ...(usersAl3MdMgUsQMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: usersAl3MdMgUsQMeta?.alias || [],
    redirect: usersAl3MdMgUsQMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/[id]/users.vue").then(m => m.default || m)
  },
  {
    name: newSxZKsHanVSMeta?.name ?? "admin-accounts-new",
    path: newSxZKsHanVSMeta?.path ?? "new",
    meta: { ...(newSxZKsHanVSMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: newSxZKsHanVSMeta?.alias || [],
    redirect: newSxZKsHanVSMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/accounts/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: connectionsJ8i63Q8r4cMeta?.name ?? "admin-connections",
    path: connectionsJ8i63Q8r4cMeta?.path ?? "connections",
    meta: { ...(connectionsJ8i63Q8r4cMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: connectionsJ8i63Q8r4cMeta?.alias || [],
    redirect: connectionsJ8i63Q8r4cMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/connections.vue").then(m => m.default || m)
  },
  {
    name: data_migrationsLubp8sG5wuMeta?.name ?? "admin-data_migrations",
    path: data_migrationsLubp8sG5wuMeta?.path ?? "data_migrations",
    meta: { ...(data_migrationsLubp8sG5wuMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: data_migrationsLubp8sG5wuMeta?.alias || [],
    redirect: data_migrationsLubp8sG5wuMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/data_migrations.vue").then(m => m.default || m)
  },
  {
    name: users42BtJdDyESMeta?.name ?? "admin-users",
    path: users42BtJdDyESMeta?.path ?? "users",
    meta: { ...(users42BtJdDyESMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: users42BtJdDyESMeta?.alias || [],
    redirect: users42BtJdDyESMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/users.vue").then(m => m.default || m),
    children: [
  {
    name: accountsRvTTPx8Z8EMeta?.name ?? "admin-users-id-accounts",
    path: accountsRvTTPx8Z8EMeta?.path ?? ":id()/accounts",
    meta: { ...(accountsRvTTPx8Z8EMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: accountsRvTTPx8Z8EMeta?.alias || [],
    redirect: accountsRvTTPx8Z8EMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/admin/users/[id]/accounts.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: billingb5vkK9HUBNMeta?.name ?? undefined,
    path: billingb5vkK9HUBNMeta?.path ?? "/billing",
    meta: { ...(billingb5vkK9HUBNMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: billingb5vkK9HUBNMeta?.alias || [],
    redirect: billingb5vkK9HUBNMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing.vue").then(m => m.default || m),
    children: [
  {
    name: activityiCN9QwbKuKMeta?.name ?? "billing-activity",
    path: activityiCN9QwbKuKMeta?.path ?? "activity",
    meta: { ...(activityiCN9QwbKuKMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: activityiCN9QwbKuKMeta?.alias || [],
    redirect: activityiCN9QwbKuKMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/activity.vue").then(m => m.default || m)
  },
  {
    name: indexHqd7aSYW5jMeta?.name ?? "billing",
    path: indexHqd7aSYW5jMeta?.path ?? "",
    meta: { ...(indexHqd7aSYW5jMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexHqd7aSYW5jMeta?.alias || [],
    redirect: indexHqd7aSYW5jMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/index.vue").then(m => m.default || m)
  },
  {
    name: walletWtNXkDIYcVMeta?.name ?? "billing-wallet",
    path: walletWtNXkDIYcVMeta?.path ?? "wallet",
    meta: { ...(walletWtNXkDIYcVMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: walletWtNXkDIYcVMeta?.alias || [],
    redirect: walletWtNXkDIYcVMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/wallet.vue").then(m => m.default || m),
    children: [
  {
    name: delete2XwPtVWvwDMeta?.name ?? "billing-wallet-payment-delete",
    path: delete2XwPtVWvwDMeta?.path ?? "payment/delete",
    meta: { ...(delete2XwPtVWvwDMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: delete2XwPtVWvwDMeta?.alias || [],
    redirect: delete2XwPtVWvwDMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/wallet/payment/delete.vue").then(m => m.default || m)
  },
  {
    name: editEcv7F3PvVUMeta?.name ?? "billing-wallet-payment-edit",
    path: editEcv7F3PvVUMeta?.path ?? "payment/edit",
    meta: { ...(editEcv7F3PvVUMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: editEcv7F3PvVUMeta?.alias || [],
    redirect: editEcv7F3PvVUMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/wallet/payment/edit.vue").then(m => m.default || m)
  },
  {
    name: index9cH4NhLNk5Meta?.name ?? "billing-wallet-payment",
    path: index9cH4NhLNk5Meta?.path ?? "payment",
    meta: { ...(index9cH4NhLNk5Meta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: index9cH4NhLNk5Meta?.alias || [],
    redirect: index9cH4NhLNk5Meta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/wallet/payment/index.vue").then(m => m.default || m)
  },
  {
    name: replacepQWEpi0K5sMeta?.name ?? "billing-wallet-payment-replace",
    path: replacepQWEpi0K5sMeta?.path ?? "payment/replace",
    meta: { ...(replacepQWEpi0K5sMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: replacepQWEpi0K5sMeta?.alias || [],
    redirect: replacepQWEpi0K5sMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/wallet/payment/replace.vue").then(m => m.default || m)
  },
  {
    name: rechargejmUwfYhK2gMeta?.name ?? "billing-wallet-recharge",
    path: rechargejmUwfYhK2gMeta?.path ?? "recharge",
    meta: { ...(rechargejmUwfYhK2gMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: rechargejmUwfYhK2gMeta?.alias || [],
    redirect: rechargejmUwfYhK2gMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/billing/wallet/recharge.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: select_accountUIV5dwdMZFMeta?.name ?? "connect-code-select_account",
    path: select_accountUIV5dwdMZFMeta?.path ?? "/connect/:code()/select_account",
    meta: { ...(select_accountUIV5dwdMZFMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: select_accountUIV5dwdMZFMeta?.alias || [],
    redirect: select_accountUIV5dwdMZFMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/connect/[code]/select_account.vue").then(m => m.default || m)
  },
  {
    name: connectionsp2L30vHwARMeta?.name ?? undefined,
    path: connectionsp2L30vHwARMeta?.path ?? "/connections",
    meta: { ...(connectionsp2L30vHwARMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: connectionsp2L30vHwARMeta?.alias || [],
    redirect: connectionsp2L30vHwARMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/connections.vue").then(m => m.default || m),
    children: [
  {
    name: indexlqbKfgpNV9Meta?.name ?? "connections",
    path: indexlqbKfgpNV9Meta?.path ?? "",
    meta: { ...(indexlqbKfgpNV9Meta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexlqbKfgpNV9Meta?.alias || [],
    redirect: indexlqbKfgpNV9Meta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/connections/index.vue").then(m => m.default || m)
  },
  {
    name: invitationsjlwYk0Gk2SMeta?.name ?? "connections-invitations",
    path: invitationsjlwYk0Gk2SMeta?.path ?? "invitations",
    meta: { ...(invitationsjlwYk0Gk2SMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: invitationsjlwYk0Gk2SMeta?.alias || [],
    redirect: invitationsjlwYk0Gk2SMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/connections/invitations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: domainsXUaCqEg9q6Meta?.name ?? "domains",
    path: domainsXUaCqEg9q6Meta?.path ?? "/domains",
    meta: { ...(domainsXUaCqEg9q6Meta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: domainsXUaCqEg9q6Meta?.alias || [],
    redirect: domainsXUaCqEg9q6Meta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/domains.vue").then(m => m.default || m),
    children: [
  {
    name: feedbackY1Jw7YFmIgMeta?.name ?? "domains-id-feedback",
    path: feedbackY1Jw7YFmIgMeta?.path ?? ":id()/feedback",
    meta: { ...(feedbackY1Jw7YFmIgMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: feedbackY1Jw7YFmIgMeta?.alias || [],
    redirect: feedbackY1Jw7YFmIgMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/domains/[id]/feedback.vue").then(m => m.default || m)
  },
  {
    name: verifyoylBcmy9QhMeta?.name ?? "domains-id-verify",
    path: verifyoylBcmy9QhMeta?.path ?? ":id()/verify",
    meta: { ...(verifyoylBcmy9QhMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: verifyoylBcmy9QhMeta?.alias || [],
    redirect: verifyoylBcmy9QhMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/domains/[id]/verify.vue").then(m => m.default || m)
  }
]
  },
  {
    name: featuresEZQ12QZSL4Meta?.name ?? "features",
    path: featuresEZQ12QZSL4Meta?.path ?? "/features",
    meta: { ...(featuresEZQ12QZSL4Meta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: featuresEZQ12QZSL4Meta?.alias || [],
    redirect: featuresEZQ12QZSL4Meta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/features.vue").then(m => m.default || m),
    children: [
  {
    name: pricingUPZ4WiPoVyMeta?.name ?? "features-productId-component-featureId-pricing",
    path: pricingUPZ4WiPoVyMeta?.path ?? ":productId()/:component()/:featureId()/pricing",
    meta: { ...(pricingUPZ4WiPoVyMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: pricingUPZ4WiPoVyMeta?.alias || [],
    redirect: pricingUPZ4WiPoVyMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/[featureId]/pricing.vue").then(m => m.default || m)
  },
  {
    name: disable4mVzWnfb1dMeta?.name ?? "features-productId-component-disable",
    path: disable4mVzWnfb1dMeta?.path ?? ":productId()/:component()/disable",
    meta: { ...(disable4mVzWnfb1dMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: disable4mVzWnfb1dMeta?.alias || [],
    redirect: disable4mVzWnfb1dMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/disable.vue").then(m => m.default || m)
  },
  {
    name: enablerd20ijDAHsMeta?.name ?? "features-productId-component-enable",
    path: enablerd20ijDAHsMeta?.path ?? ":productId()/:component()/enable",
    meta: { ...(enablerd20ijDAHsMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: enablerd20ijDAHsMeta?.alias || [],
    redirect: enablerd20ijDAHsMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/enable.vue").then(m => m.default || m)
  },
  {
    name: forbiddens1jod3goiPMeta?.name ?? "features-productId-component-forbidden",
    path: forbiddens1jod3goiPMeta?.path ?? ":productId()/:component()/forbidden",
    meta: { ...(forbiddens1jod3goiPMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: forbiddens1jod3goiPMeta?.alias || [],
    redirect: forbiddens1jod3goiPMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/features/[productId]/[component]/forbidden.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexKZIZ5jlI0zMeta?.name ?? "index",
    path: indexKZIZ5jlI0zMeta?.path ?? "/",
    meta: { ...(indexKZIZ5jlI0zMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexKZIZ5jlI0zMeta?.alias || [],
    redirect: indexKZIZ5jlI0zMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profile8UAJvSAdNYMeta?.name ?? "profile",
    path: profile8UAJvSAdNYMeta?.path ?? "/profile",
    meta: { ...(profile8UAJvSAdNYMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: profile8UAJvSAdNYMeta?.alias || [],
    redirect: profile8UAJvSAdNYMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tc1qfywrEYMeta?.name ?? "redirect-accounts-id",
    path: _91id_93tc1qfywrEYMeta?.path ?? "/redirect/accounts/:id()",
    meta: { ...(_91id_93tc1qfywrEYMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: _91id_93tc1qfywrEYMeta?.alias || [],
    redirect: _91id_93tc1qfywrEYMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/redirect/accounts/[id].vue").then(m => m.default || m)
  },
  {
    name: join_accountyBi6OH4PxrMeta?.name ?? "redirect-join_account",
    path: join_accountyBi6OH4PxrMeta?.path ?? "/redirect/join_account",
    meta: { ...(join_accountyBi6OH4PxrMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: join_accountyBi6OH4PxrMeta?.alias || [],
    redirect: join_accountyBi6OH4PxrMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/redirect/join_account.vue").then(m => m.default || m)
  },
  {
    name: status5Rj8vZoTubMeta?.name ?? "redirect-status",
    path: status5Rj8vZoTubMeta?.path ?? "/redirect/status",
    meta: { ...(status5Rj8vZoTubMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: status5Rj8vZoTubMeta?.alias || [],
    redirect: status5Rj8vZoTubMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/redirect/status.vue").then(m => m.default || m)
  },
  {
    name: indexTXB5gBxVjSMeta?.name ?? "register-registrationPath",
    path: indexTXB5gBxVjSMeta?.path ?? "/register/:registrationPath()",
    meta: { ...(indexTXB5gBxVjSMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexTXB5gBxVjSMeta?.alias || [],
    redirect: indexTXB5gBxVjSMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/register/[registrationPath]/index.vue").then(m => m.default || m)
  },
  {
    name: select_accountjKGqxYdVRxMeta?.name ?? "select_account",
    path: select_accountjKGqxYdVRxMeta?.path ?? "/select_account",
    meta: { ...(select_accountjKGqxYdVRxMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: select_accountjKGqxYdVRxMeta?.alias || [],
    redirect: select_accountjKGqxYdVRxMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/select_account.vue").then(m => m.default || m)
  },
  {
    name: settingsxR4N0YiP3YMeta?.name ?? "settings",
    path: settingsxR4N0YiP3YMeta?.path ?? "/settings",
    meta: { ...(settingsxR4N0YiP3YMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: settingsxR4N0YiP3YMeta?.alias || [],
    redirect: settingsxR4N0YiP3YMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: unconfirmedxGyj4wleh8Meta?.name ?? "unconfirmed",
    path: unconfirmedxGyj4wleh8Meta?.path ?? "/unconfirmed",
    meta: { ...(unconfirmedxGyj4wleh8Meta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: unconfirmedxGyj4wleh8Meta?.alias || [],
    redirect: unconfirmedxGyj4wleh8Meta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/unconfirmed.vue").then(m => m.default || m)
  },
  {
    name: indexvb9dbeaXa8Meta?.name ?? "users-id",
    path: indexvb9dbeaXa8Meta?.path ?? "/users/:id()",
    meta: { ...(indexvb9dbeaXa8Meta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexvb9dbeaXa8Meta?.alias || [],
    redirect: indexvb9dbeaXa8Meta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUa9lZFzYStMeta?.name ?? "users-current",
    path: indexUa9lZFzYStMeta?.path ?? "/users/current",
    meta: { ...(indexUa9lZFzYStMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexUa9lZFzYStMeta?.alias || [],
    redirect: indexUa9lZFzYStMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/users/current/index.vue").then(m => m.default || m)
  },
  {
    name: indexaiCgbDmraOMeta?.name ?? "users",
    path: indexaiCgbDmraOMeta?.path ?? "/users",
    meta: { ...(indexaiCgbDmraOMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexaiCgbDmraOMeta?.alias || [],
    redirect: indexaiCgbDmraOMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: newXHQJ3B1ZMBMeta?.name ?? "users-new",
    path: newXHQJ3B1ZMBMeta?.path ?? "/users/new",
    meta: { ...(newXHQJ3B1ZMBMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: newXHQJ3B1ZMBMeta?.alias || [],
    redirect: newXHQJ3B1ZMBMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/users/new.vue").then(m => m.default || m)
  },
  {
    name: indexyX3aT5ghpzMeta?.name ?? "volume-itemName",
    path: indexyX3aT5ghpzMeta?.path ?? "/volume/:itemName()",
    meta: { ...(indexyX3aT5ghpzMeta || {}), ...{"middleware":["01-required-user-authenticated","analytics-identify-authenticated","datadog-identify-authenticated","fullstory-identify-authenticated","honeybadger-context-authenticated","hubspot-update-authenticated","intercom-update-authenticated","zendesk-settings-authenticated"]} },
    alias: indexyX3aT5ghpzMeta?.alias || [],
    redirect: indexyX3aT5ghpzMeta?.redirect,
    component: () => import("/home/runner/work/sso/sso/client/pages/volume/[itemName]/index.vue").then(m => m.default || m)
  }
]