import revive_payload_client_4sVQNw7RlN from "/home/runner/work/sso/sso/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/sso/sso/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/sso/sso/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/sso/sso/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/sso/sso/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/sso/sso/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/sso/sso/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/sso/sso/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_datadogRum_client_kvyTdQ3bKl from "/home/runner/work/sso/sso/client/plugins/01.datadogRum.client.ts";
import fullstory_client_lcOseQPvzQ from "/home/runner/work/sso/sso/client/plugins/fullstory.client.ts";
import google_analytics_client_KruZkwwzId from "/home/runner/work/sso/sso/client/plugins/google-analytics.client.ts";
import honeybadger_client_uXWiAYUVus from "/home/runner/work/sso/sso/client/plugins/honeybadger.client.ts";
import hubspot_client_G9hBZcYpPa from "/home/runner/work/sso/sso/client/plugins/hubspot.client.ts";
import intercom_page_tracker_directive_iMJBt0Djrj from "/home/runner/work/sso/sso/client/plugins/intercom-page-tracker.directive.ts";
import intercom_client_vr7NPky0pK from "/home/runner/work/sso/sso/client/plugins/intercom.client.ts";
import mixpanel_client_JbuVUz27YJ from "/home/runner/work/sso/sso/client/plugins/mixpanel.client.ts";
import recaptcha_85gNSCNFUU from "/home/runner/work/sso/sso/client/plugins/recaptcha.ts";
import trackEvent_client_eFvPoiSL6E from "/home/runner/work/sso/sso/client/plugins/trackEvent.client.ts";
import trackPageview_client_DCdyZhdPtq from "/home/runner/work/sso/sso/client/plugins/trackPageview.client.ts";
import ui_shared_assets_client_44hUGEvgmq from "/home/runner/work/sso/sso/client/plugins/ui-shared-assets.client.ts";
import vue_dompurify_html_ER8SW1XOdk from "/home/runner/work/sso/sso/client/plugins/vue-dompurify-html.ts";
import zendesk_client_8Sfi4oX528 from "/home/runner/work/sso/sso/client/plugins/zendesk.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  _01_datadogRum_client_kvyTdQ3bKl,
  fullstory_client_lcOseQPvzQ,
  google_analytics_client_KruZkwwzId,
  honeybadger_client_uXWiAYUVus,
  hubspot_client_G9hBZcYpPa,
  intercom_page_tracker_directive_iMJBt0Djrj,
  intercom_client_vr7NPky0pK,
  mixpanel_client_JbuVUz27YJ,
  recaptcha_85gNSCNFUU,
  trackEvent_client_eFvPoiSL6E,
  trackPageview_client_DCdyZhdPtq,
  ui_shared_assets_client_44hUGEvgmq,
  vue_dompurify_html_ER8SW1XOdk,
  zendesk_client_8Sfi4oX528
]